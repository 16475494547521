import { findUserRequest } from 'api/Samples/api';
import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { getUsers } from 'store/users/actions';
import { SET_USERS } from 'store/users/types';

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useFindUser = () => {
  const dispatch = useDispatch();
  const { setPageParam } = usePaginationSearchParams();
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  useEffect(() => {
    return () => {
      setError('');
      setLoading(false);
    };
  }, []);

  const findUser = (sampleId: string) => {
    setPageParam('1');

    if (sampleId === '') {
      // reset data when input is empty
      dispatch(getUsers({ page: '1', pageSize: '10' }));
    } else {
      setError('');
      setLoading(true);

      findUserRequest(sampleId)
        .then(({ data }) => {
          if (data) {
            dispatch(getUsers({ page: '1', pageSize: '10', username: data.toString() }));
          } else {
            // if found nothing then clear table
            dispatch({ type: SET_USERS, users: [] });
          }
        })
        .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
        .finally(() => setLoading(false));
    }
  };

  return {
    findUser,
    isLoading,
    error,
    setError,
  };
};
