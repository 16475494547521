import { getConditionsRequest } from 'api/FHIR/api';
import { Condition } from 'api/FHIR/types';
import { ConditionsFilter } from 'common/types/SearchParams';
import { Pagination } from 'common/types/Pagination';

import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import omit from 'lodash/omit';
import { camelizeKeys } from 'humps';

const useGetConditions = (userId: string) => {
  const { t } = useTranslation();

  const [ conditions, setConditions ] = useState<Condition[]>([]);
  const [ pagination, setPagination ] = useState<Pagination | undefined>();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>('');

  const getConditions = useCallback((filters: ConditionsFilter, abortController: AbortController) => {
    setIsLoading(true);
    setError('');

    getConditionsRequest(userId, filters, abortController)
      .then((response) => {
        setConditions(camelizeKeys(response.data.results) as Condition[]);
        setPagination(omit(response.data, 'results'));
      })
      .catch((error) => {
        if (!abortController?.signal.aborted) {
          setError(error?.response?.data?.message || t('errors.server_error_try_again'));
        }
      })
      .finally(() => {
        if (!abortController?.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, [ t, userId ]);

  return { getConditions, conditions, isLoading, error, pagination };
};

export default useGetConditions;
