import { FormTextField, Label } from 'common/components';
import useResendActivationLink from 'components/Dashboard/components/Authorization/hooks/useResendActivationLink';

import React from 'react';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';

const VerifyPage = () => {
  const { t } = useTranslation();
  const { email, setEmail, submitError, success, isSubmitting, resendActivationLink } = useResendActivationLink();

  return (
    <Box data-testid="resend-link-container">

      <Label>{t('verify_page.activation_link_invalid')}</Label>

      <Typography marginTop="0px" marginBottom={{ xs: 8, sm: 5 }}>
        {t('dashboard.verify_account.error_something_went_wrong')}
      </Typography>

      <FormTextField
        id="email"
        name="email"
        type="email"
        label={t('general.email')}
        value={email}
        onChange={e => setEmail(e.target.value)}
        disabled={false}
      />

      {submitError && (
        <Box alignSelf="flex-start">
          <Typography color="error" variant="subtitle2">
            {submitError}
          </Typography>
        </Box>
      )}

      {success && (
        <Box alignSelf="flex-start" marginTop={2}>
          <Typography variant="subtitle2" color="success">
            {t('verify_page.activation_link_sent')}
          </Typography>
        </Box>
      )}

      <Box display="flex" justifyContent="flex-start" width="100%" marginTop={{ xs: 3, sm: 5 }}>
        <LoadingButton
          sx={{ width: { xs: '100%', sm: 'unset' } }}
          loading={isSubmitting}
          disabled={!email || success}
          onClick={resendActivationLink}
          variant="contained"
        >
          {t('verify_page.button_request_link')}
        </LoadingButton>
      </Box>

    </Box>
  );
};

export default VerifyPage;
