import { NAVIGATION_ITEMS } from 'common/constants/navigation';
import LogoIcon from 'common/icons/LogoIcon';
import { RootState } from 'store';
import { logout } from 'store/auth/actions';
import NavigationMenu from 'components/Landing/components/MainContent/components/ApplicationBar/components/NavigationMenu/NavigationMenu';

import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  Container,
  Hidden,
  Toolbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ApplicationBar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { myUser, isMyUserLoading } = useSelector((state: RootState) => state.user);
  const onLogoutClick = React.useCallback(() => {
    dispatch(logout());
  }, [ dispatch ]);
  const navigateToSignIn = React.useCallback(() => navigate('/dashboard'), [ navigate ]);

  const renderLanguageSwitcher = () => {
    const isEnglish = i18n.language === 'en';

    return (
      <>
        <Box marginLeft={1}>
          <Button
            color="primary"
            variant="text"
            onClick={() => i18n.changeLanguage('es')}
            sx={{ textTransform: 'none', fontWeight: !isEnglish ? 700 : 500 }}
          >
            {t('general.es_lang')}
          </Button>
        </Box>
        <Box marginLeft={1}>
          <Button
            color="primary"
            variant="text"
            onClick={() => i18n.changeLanguage('en')}
            sx={{ textTransform: 'none', fontWeight: isEnglish ? 700 : 500 }}
          >
            {t('general.en_lang')}
          </Button>
        </Box>
      </>
    );
  };

  const renderNavigationButtons = React.useCallback(() => NAVIGATION_ITEMS.map((page) => {
    if (page.condition && !page.condition(myUser)) {
      return null;
    }

    return (
      <Box marginLeft={1} key={page.label}>
        <Button
          color="primary"
          variant="text"
          onClick={() => navigate(page.path)}
          data-testid={page.label}
          sx={{ textTransform: 'none' }}
        >
          {t(page.label)}
        </Button>
      </Box>
    );
  }), [ navigate, t, myUser ]);

  const renderAuthButtons = useCallback(() => !isMyUserLoading && (
    <Button
      sx={{
        width: '102px',
        textTransform: 'none',
      }}
      variant="outlined"
      color="primary"
      onClick={navigateToSignIn}
      data-testid="login-button"
    >
      {t('auth.signin')}
    </Button>
  ), [ isMyUserLoading, navigateToSignIn, t ]);

  const renderUserProfile = useCallback(() => (
    <>
      { /* TODO profile icon and so on ? */}
      <Button
        variant="outlined"
        data-testid="logout-button"
        onClick={onLogoutClick}
        sx={{ textTransform: 'none' }}
      >
        {t('auth.logout')}
      </Button>
    </>
  ), [ onLogoutClick, t ]);

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Hidden lgDown>
            <Box marginRight={7}>
              <LogoIcon
                color="primary"
                onClick={() => navigate('/')}
                sx={{ marginTop: '5px', width: 200, height: 62, cursor: 'pointer' }}
              />
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Box sx={{ flexGrow: 0 }}>
              <NavigationMenu />
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Box sx={{ flexGrow: 1 }} height={120}>
              <LogoIcon
                color="primary"
                onClick={() => navigate('/')}
                sx={{
                  height: 120,
                  width: 180,
                  cursor: 'pointer',
                }}
              />
            </Box>
          </Hidden>
          <Box sx={{ flexGrow: 1, flexDirection: 'row-reverse', display: { xs: 'none', lg: 'flex' } }}>
            {renderNavigationButtons()}
            {renderLanguageSwitcher()}
          </Box>
          <Box marginLeft={3} sx={{ flexGrow: 0 }}>
            {
              !myUser.id ?
                renderAuthButtons() :
                renderUserProfile()
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ApplicationBar;
