import { EHRStatus, User } from 'api/User/types';
import { SyncStatus } from 'common/types/Freezerworks';
import { UserState } from 'store/user/models';
import { RESET_USER, SET_MY_USER, SET_MY_USER_LOADING, UPDATE_ANCESTRY_FILES } from 'store/user/types';

import { AnyAction } from 'redux';

export const initialUser: User = {
  id: 0,
  username: '',
  firstName: '',
  lastName: '',
  accessRights: 0,
  survey: null,
  surveyCompletedPercentage: 0,
  surveyVersion: 1,
  kitId: null,
  signature: '',
  parentSignature: '',
  returnShippingStatus: null,
  directShippingStatus: null,
  ancestryFiles: [],
  ancestries: [],
  partnerId: null,
  partnerName: null,
  createdAt: '',
  utmParams: null,
  registrationStatus: 'Registered',
  providerName: null,
  providerId: null,
  ehrStatus: EHRStatus.NOT_PULLED,
  organization: null,
  organizationSite: null,
  testKitReceived: null,
  healthGorillaId: null,
  mailingAddress: {
    zip: null,
    state: null,
    city: null,
    street1: null,
    street2: null,
    country: null,
    previousZipCodes: [],
  },
  samples: [],
  phoneNumber: null,
  mrn: null,
  freezerworksStatus: {
    id: null,
    syncStatus: SyncStatus.NOT_SYNC,
    syncErrorMessage: null,
  },
};

export const initialState: UserState = {
  isMyUserLoading: false,
  myUser: initialUser,
};

export const userReducer = (
  state: UserState = { ...initialState },
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_MY_USER_LOADING:
      return {
        ...state,
        isMyUserLoading: action.value,
      };

    case SET_MY_USER:
      return {
        ...state,
        myUser: {
          ...action.user,
        } as User,
        isMyUserLoading: false,
      };

    case RESET_USER:
      return {
        ...state,
        myUser: initialUser,
      };

    case UPDATE_ANCESTRY_FILES:
      return {
        ...state,
        myUser: {
          ...state.myUser,
          ancestryFiles: action.data,
        } as User,
      };

    default:
      return { ...state };
  }
};
