import { axiosInstance } from 'api/instance';
import { FileFormat } from 'api/User/types';
import PaginatedData from 'common/types/PaginatedData';
import { getSafeFilename } from 'common/helpers/getSafeFilename';
import { ReportsFilter } from 'common/types/SearchParams';
import { Ancestry, UserReportRequest, AncestryProvider, UserReport } from 'api/Reports/types';

import { AxiosResponse } from 'axios';

export const getUserReports = (
  filter: ReportsFilter,
  abortController: AbortController,
): Promise<AxiosResponse<PaginatedData<UserReport>>> => {
  const organizationIds = filter.organizationIds?.length === 0 ? [] : filter.organizationIds?.split(',');
  return axiosInstance.post<PaginatedData<UserReport>>(
    `users/science-report?page=${filter.page}&pageSize=${filter.pageSize}`,
    { ...filter, organizationIds },
    { signal: abortController?.signal },
  );
};

export const exportUserReportsRequest = (filter: ReportsFilter, fileFormat = FileFormat.CSV): Promise<AxiosResponse> => {
  const organizationIds = filter.organizationIds?.length === 0 ? [] : filter.organizationIds?.split(',');
  return axiosInstance.post(
    'users/science-report/csv',
    { ...filter, organizationIds, fileFormat },
  );
};

export const getUserAncestryFile = (ancestryFileId:string, filename: string) => {
  return axiosInstance.get(`users/ancestry?ancestryId=${ancestryFileId}`, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([ response.data ]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', getSafeFilename(filename));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const addUserReportRequest = ({ userId, source, file }: UserReportRequest): Promise<AxiosResponse<Ancestry[]>> => {
  // if selected source is WGS then we don't need to pass ancestryProcessingId param
  const url = [
    'users/report?',
    `userId=${userId}`,
    source !== AncestryProvider.WHOLE_GENOME_SEQUENCING ? `&ancestryProcessingId=${source}` : undefined,
  ].join('');
  return axiosInstance.post<Ancestry[]>(
    url,
    file,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
};
