import { AncestryFile } from 'api/User/types';
import { ShippingStatus } from 'api/Shipment/types';

export enum AncestryProvider {
  WHOLE_GENOME_SEQUENCING = 'wgs',
  TWENTY_THREE_AND_ME = '23andme',
  ANCESTRY = 'ancestry',
  MYHERITAGE = 'myheritage',
  OTHER = 'other',
  EMPTY = '',
}

export interface Ancestry {
  providerName: AncestryProvider;
  reportDate: string;
  afr: number;
  amr: number;
  eas: number;
  eur: number;
  sas: number;
  oce: number;
  lme: number;
}

export interface UserReportRequest {
  userId: number;
  source: string | null;
  file: FormData;
}

export type AncestryFileLink = Pick<AncestryFile, 'id' | 'sourceFileUploadedAt'> & {
  fileName: string;
};

// todo portocol naming
export interface PRSReport {
  diabetes: RiskProbability;
  hypertension: RiskProbability;
  celiac: RiskProbability;
  gout: RiskProbability;
  colitis: RiskProbability;
}

// todo portocol naming
export enum RiskProbability {
  LOW = 'Low probability',
  LIKELY_LOW = 'Likely low',
  AVERAGE = 'Average',
  LIKELY_HIGH = 'Likely high',
  HIGH = 'High probability',
}

export interface UserReport {
  id: number;
  firstName: string | null; // If a user is a data science admin then they must not see these fields for hipaa compliance reasons
  lastName: string | null;
  username: string | null;
  ancestryFiles: AncestryFile[];
  returnShippingStatus?: ShippingStatus;
  ancestries: Ancestry[];
}
