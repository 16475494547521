import { darkBlue } from 'common/constants/colors';
import { isSuperAdmin, getUSZipCodeRegExp } from 'common/helpers/user';
import { RootState } from 'store';
import { EHRStatus, UserView } from 'api/User/types';
import { getBadgeColor, getEHRStatusLabel, hasPopover } from 'components/AdminDashboard/components/UsersPage/components/EHRColumn/helpers';
import EHRStatusPopover from 'components/AdminDashboard/components/UsersPage/components/EHRStatusPopover/EHRStatusPopover';
import RestartEHRModal from 'components/AdminDashboard/components/UsersPage/components/RestartEHRModal/RestartEHRModal';
import { getUserPipelineExecution } from 'store/users/actions';

import React, { MouseEvent, useCallback, useState, useMemo } from 'react';
import { Badge, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { RestartAlt } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  user: UserView;
  goToEhr: (id: number) => void;
}

const EHRColumn: React.FC<Props> = ({ user, goToEhr }) => {
  const dispatch = useDispatch();
  const { myUser } = useSelector((store: RootState) => store.user);
  const statusLabel = getEHRStatusLabel(user.ehrStatus);
  const color = getBadgeColor(user.ehrStatus);
  const isPulled = user.ehrStatus === EHRStatus.PULLED;
  const isNotPulled = user.ehrStatus === EHRStatus.NOT_PULLED;
  const isInProgress = user.ehrStatus === EHRStatus.IN_PROGRESS;
  const canNotImport = user.ehrStatus === EHRStatus.CAN_NOT_IMPORT;
  const popoverStatuses = useMemo(() => [ EHRStatus.FAILED, EHRStatus.NO_EHR_DATA, EHRStatus.PATIENT_CHART_ERROR_454 ], []);
  const isZipCodeValid = user.mailingAddress.zip && getUSZipCodeRegExp().test(user.mailingAddress.zip);

  const fetchUserPipelineExecution = useCallback((userId: number, finalCallback: () => void) => {
    dispatch(getUserPipelineExecution(userId, finalCallback));
  }, [ dispatch ]);

  const [ restartEHRFlowUserId, setRestartEHRFlowUserId ] = useState<number | null>(null);

  const [ EHRPopover, setEHRPopover ] = useState<{ el: HTMLElement | null, userId: number | null }>({ el: null, userId: null });

  const openEHRPopover = useCallback((event: React.MouseEvent<HTMLElement>, user: UserView) => {
    const { currentTarget } = event;
    const isExecutionEmpty = user.hgPipelineError === undefined;
    const isStatusAcceptable = popoverStatuses.includes(user.ehrStatus);
    if (isStatusAcceptable && isExecutionEmpty) {
      fetchUserPipelineExecution(user.id, () => setEHRPopover({ el: currentTarget, userId: user.id }));
    } else {
      setEHRPopover({ el: currentTarget, userId: user.id });
    }
  }, [ fetchUserPipelineExecution, popoverStatuses ]);

  return (
    <>
      <Typography component="div" display="flex" alignItems="center" flexWrap="wrap" variant="textSRegular" sx={{ whiteSpace: 'nowrap' }}>

        <Badge color={color} variant="dot" sx={{ margin: '2px 8px 0 0' }} />

        {hasPopover(user.ehrStatus) ? (
          <Typography
            variant="textSRegular"
            component="div"
            sx={{
              textDecorationStyle: 'dashed',
              textDecorationLine: 'underline',
              cursor: 'pointer',
            }}
            onClick={(e: MouseEvent<HTMLElement>) => openEHRPopover(e, user)}
            data-testid="label-status-hint"
          >
            {statusLabel}
          </Typography>
        ) : statusLabel}

        {isSuperAdmin(myUser) && (
          <Tooltip title={isNotPulled ? 'Pull data' : 'Restart flow'}>
            <Box component="span">
              <IconButton disabled={isInProgress || canNotImport || !isZipCodeValid} color="primary" size="small" onClick={() => setRestartEHRFlowUserId(user.id)}>
                <RestartAlt />
              </IconButton>
            </Box>
          </Tooltip>
        )}

        {isPulled && (
          <Typography
            variant="button"
            sx={{ color: darkBlue, cursor: 'pointer', textDecoration: 'underline', fontSize: '0.75rem', flexBasis: '100%' }}
            onClick={() => goToEhr(user.id)}
            data-testid="ehr-hint"
          >
            View clinical data
          </Typography>
        )}

      </Typography>

      {EHRPopover.el && EHRPopover.userId && (
        <EHRStatusPopover anchorEl={EHRPopover.el} onClose={() => setEHRPopover({ el: null, userId: null })} userId={EHRPopover.userId} />
      )}

      {restartEHRFlowUserId && (
        <RestartEHRModal onCancel={() => setRestartEHRFlowUserId(null)} userId={user.id} isNotPulled={isNotPulled} />
      )}
    </>
  );
};

export default EHRColumn;
