import { DateTime } from 'luxon';

export const formatDateTime = (date: string | undefined) => date && DateTime.fromISO(String(date)).toFormat('MM/dd/yyyy h:mma');

export const formatDate = (date: string | undefined | null) => date && DateTime.fromISO(String(date)).toFormat('MM/dd/yyyy');

export const ageFromGivenDate = (givenDate: string): number => {
  const today = new Date();
  const date = new Date(givenDate);
  let age = today.getFullYear() - date.getFullYear();
  const m = today.getMonth() - date.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }

  return age;
};
