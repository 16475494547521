import { axiosInstance } from 'api/instance';
import {
  AncestryFile,
  CreateUserRequestParams,
  FileFormat,
  MailingAddress,
  PartnerInfo,
  PipelineExecutionResponse,
  Survey,
  UpdateMyUserParams,
  UpdateUserRequestParams,
  User,
  UserAccessLevel,
  UserView,
  WithKit,
} from 'api/User/types';
import { CommonAuthResponse } from 'api/Auth/types';
import { getUsersFilter } from 'common/helpers/user';
import PaginatedData from 'common/types/PaginatedData';
import { UsersFilter } from 'common/types/SearchParams';

import { AxiosResponse } from 'axios';

export const getMyUserRequest = (): Promise<AxiosResponse<User>> => {
  return axiosInstance.get<User>('users/me');
};

export const updateMyUserRequest = (userParams: UpdateMyUserParams): Promise<AxiosResponse<User>> => {
  return axiosInstance.put<User>('users', userParams);
};

export const updateMyAddressRequest = (address: Omit<MailingAddress, 'previousZipCodes'>): Promise<AxiosResponse<User>> => {
  return axiosInstance.put<User>('users/address', address);
};

export const updateMySurveyRequest = (survey: Partial<Survey>): Promise<AxiosResponse<User>> => {
  return axiosInstance.put<User>('users/survey', survey);
};

export const getUserRequest = (userId: string): Promise<AxiosResponse<User>> => {
  return axiosInstance.get<User>(`users/${userId}`);
};

export const updateUserRequest = (id: number, params: UpdateUserRequestParams): Promise<AxiosResponse> => {
  return axiosInstance.put(`users/${id}`, { ...params });
};

export const getPartnerInfoRequest = (partnerId: string): Promise<AxiosResponse<PartnerInfo>> => {
  return axiosInstance.get<PartnerInfo>(`invitees/${partnerId}`);
};

export const addAdmin = (userEmail: string, accessLevel: UserAccessLevel): Promise<AxiosResponse> => {
  return axiosInstance.post('users/add-admin', {
    userEmail,
    accessRights: accessLevel,
  });
};

export const changeAccessLevel = (userId: number, accessLevel: UserAccessLevel): Promise<AxiosResponse> => {
  return axiosInstance.post('users/change-access', {
    userId,
    accessRights: accessLevel,
  });
};

export const changeOrganization = (userId: number, organizationId: number | null): Promise<AxiosResponse> => {
  return axiosInstance.post('users/organization', {
    userId,
    organizationId,
  });
};

export const forgotPasswordRequest = (email: string): Promise<AxiosResponse> => {
  return axiosInstance.post('users/forgot-password', { email });
};

export const setPasswordRequest = (password: string, key: string): Promise<AxiosResponse<CommonAuthResponse>> => {
  return axiosInstance.post(`users/restore-password/${key}`, { newPassword: password });
};

export const resendActivateLinkRequest = (email: string): Promise<AxiosResponse> => {
  return axiosInstance.post('users/resend-activation', { email });
};

export const postContactUs = (email: string, message: string): Promise<AxiosResponse<{ success: boolean }>> => {
  return axiosInstance.post<{ success: boolean }>('users/contact-us', { email, message });
};

export const postValidateEmail = (email: string): Promise<AxiosResponse<string>> => {
  return axiosInstance.post('users/validate-email', { email });
};

export const postAncestryFile = (fileData: FormData, provider: string): Promise<AxiosResponse<AncestryFile[]>> => {
  return axiosInstance.post(
    `users/ancestry?providerName=${provider}`,
    fileData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const getUsersRequest = (
  filter: UsersFilter,
  patient = false,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedData<UserView>>> => {
  const data = getUsersFilter(filter);
  return axiosInstance.post<PaginatedData<UserView>>(
    `users?page=${filter.page}&pageSize=${filter.pageSize}&clinicalPatient=${patient}`,
    data,
    { signal: abortController?.signal },
  );
};

export const exportUsersRequest = (
  filter: UsersFilter,
  patient = false,
  fileFormat = FileFormat.CSV,
  abortController?: AbortController,
): Promise<AxiosResponse> => {
  const data = getUsersFilter(filter);
  return axiosInstance.post(
    `users/csv?clinicalPatient=${patient}`,
    { ...data, fileFormat },
    { signal: abortController?.signal },
  );
};

export const createUserRequest = (params: CreateUserRequestParams, patient = false): Promise<AxiosResponse> => {
  return axiosInstance.post(`users/patient?clinicalPatient=${patient}`, { ...params });
};

export const createUsersBatch = (fileData: FormData, patient = false): Promise<AxiosResponse> => {
  return axiosInstance.post(
    `users/patient-batch?clinicalPatient=${patient}`,
    fileData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const restartEHRFlow = (userId: number): Promise<AxiosResponse> => {
  return axiosInstance.post(`users/hg-import/${userId}`);
};

export const syncFW = (userId: number): Promise<AxiosResponse<UserView>> => {
  return axiosInstance.post<UserView>(`users/${userId}/fw-sync/`);
};

export const getEHRExecutions = (userId: number): Promise<AxiosResponse<PipelineExecutionResponse>> => {
  return axiosInstance.get<PipelineExecutionResponse>(`users/hg-import-pipelines-executions/${userId}`);
};

export const patchLatestTestKitReceived = (userId: string): Promise<AxiosResponse<User>> => {
  return axiosInstance.put<User>(`users/switch-test-kit-received/${userId}`);
};

export const getUserKits = (page: string, pageSize: string, username: string): Promise<AxiosResponse<PaginatedData<WithKit>>> => {
  return axiosInstance.post<PaginatedData<WithKit>>(`users/with-kit?page=${page}&pageSize=${pageSize}`, { username });
};
