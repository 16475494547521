import { getFilterValueFromSearchParams } from 'common/helpers/filters';

import { useSearchParams, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState, useMemo } from 'react';

const useFilterSearchParams = <T extends object>(initParams: readonly string[]) => {
  const { search } = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const filter: T = useMemo(() => {
    return Object.fromEntries(
      initParams.map(param => [ param, getFilterValueFromSearchParams(param, searchParams.get(param)) ]),
    ) as T;
  }, [ searchParams, initParams ]);

  const [ params, setParams ] = useState<T>(filter);

  useEffect(() => {
    setParams(filter);
  }, [ filter, search ]);

  const setFilter = useCallback((params: T) => {
    initParams.forEach((paramKey) => {
      const paramValue = params[paramKey as keyof T];
      if (paramValue) {
        searchParams.set(paramKey, String(paramValue));
      } else {
        searchParams.delete(paramKey);
      }
    });

    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams, initParams ]);

  const clearFilter = () => {
    initParams.forEach(param => searchParams.delete(param));
    setSearchParams(searchParams, { replace: true });
  };

  return {
    params,
    setFilter,
    clearFilter,
  };
};

export default useFilterSearchParams;
