import { SearchParams } from 'common/types/SearchParams';

import { omit } from 'lodash';

export const getFiltersUrlString = (filters: Object): string => {
  return Object.keys(filters).reduce((result, key) => {
    const values = filters[key as keyof typeof filters].toString().split(',');
    // transforms multiple comma separated values to a view like &code=123&code=456
    const subResult = values.reduce((result, currentValue) => {
      return currentValue ? `${result}&${key}=${currentValue}` : result;
    }, '');

    return `${result}${subResult}`;
  }, '');
};

export const isFilterUsed = (filters: Object): boolean => {
  const filter = omit(filters, [ 'page', 'pageSize', 'username' ]);

  return Object.values(filter).filter(value => !!value).length > 0;
};

export const getFilterValueFromSearchParams = (param: string, value: string | null) => {
  if (value) {
    return value;
  } else if (param === SearchParams.PAGE) {
    return '1';
  } else if (param === SearchParams.ROWS) {
    return '10';
  } else {
    return '';
  }
};
