import { Observation } from 'api/FHIR/types';
import { grey200 } from 'common/constants/colors';
import { formatDate } from 'common/helpers/dateTime';

import React, { useState } from 'react';
import { TableCell, TableRow, Typography, Table, IconButton, Collapse, TableHead, TableBody, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ObservationRow: React.FC<{ row: Observation }> = ({ row }) => {
  const [ open, setOpen ] = useState<boolean>(false);

  return (
    <>
      <TableRow hover tabIndex={-1} onClick={() => setOpen(!open)} sx={{ cursor: 'pointer' }}>

        <TableCell>

          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>

        </TableCell>

        <TableCell>{row.observationStatus}</TableCell>

        <TableCell>{formatDate(row.observationIssued)}</TableCell>

        <TableCell>{row.organizationName}</TableCell>

        <TableCell>{row.codeCode}</TableCell>

        <TableCell>{row.codeSystem}</TableCell>

        <TableCell>{row.codeDisplay}</TableCell>

        <TableCell>{row.categoryCode}</TableCell>

        <TableCell>{row.categorySystem}</TableCell>

        <TableCell>{row.categoryDisplay}</TableCell>

      </TableRow>

      <TableRow>

        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: grey200 }} colSpan={10}>

          <Collapse in={open} timeout="auto" unmountOnExit>

            <Box sx={{ margin: 1 }}>

              <Typography variant="textMMedium" component="div">Values</Typography>

              <Table size="small">

                <TableHead>

                  <TableRow>

                    <TableCell>Codeable concept</TableCell>

                    <TableCell>Codeable concept display</TableCell>

                    <TableCell>Codeable concept system</TableCell>

                    <TableCell>Integer value</TableCell>

                    <TableCell>Range low</TableCell>

                    <TableCell>Range high</TableCell>

                    <TableCell>Range unit</TableCell>

                    <TableCell>Range system</TableCell>

                    <TableCell>Range code</TableCell>

                    <TableCell>String value</TableCell>

                  </TableRow>

                </TableHead>

                <TableBody>

                  <TableRow>

                    <TableCell>{row.valueCodeableconceptCode}</TableCell>

                    <TableCell>{row.valueCodeableconceptDisplay}</TableCell>

                    <TableCell>{row.valueCodeableconceptSystem}</TableCell>

                    <TableCell>{row.valueIntegerValue}</TableCell>

                    <TableCell>{row.valueRangeLow}</TableCell>

                    <TableCell>{row.valueRangeHigh}</TableCell>

                    <TableCell>{row.valueRangeUnit}</TableCell>

                    <TableCell>{row.valueRangeSystem}</TableCell>

                    <TableCell>{row.valueRangeCode}</TableCell>

                    <TableCell>{row.valueStringValue}</TableCell>

                  </TableRow>

                </TableBody>

              </Table>

            </Box>

            <Box sx={{ margin: 1 }}>

              <Typography variant="textMMedium" component="div">Values quantity</Typography>

              <Table size="small">

                <TableHead>

                  <TableRow>

                    <TableCell>Value</TableCell>

                    <TableCell>Unit</TableCell>

                    <TableCell>System</TableCell>

                    <TableCell>Code</TableCell>

                    <TableCell>Reference range low</TableCell>

                    <TableCell>Reference range high</TableCell>

                    <TableCell>Reference range unit</TableCell>

                    <TableCell>Reference range system</TableCell>

                    <TableCell>Reference range code</TableCell>

                    <TableCell>Reference range text</TableCell>

                  </TableRow>

                </TableHead>

                <TableBody>

                  <TableRow>

                    <TableCell>{row.valueQuantityValue}</TableCell>

                    <TableCell>{row.valueQuantityUnit}</TableCell>

                    <TableCell>{row.valueQuantitySystem}</TableCell>

                    <TableCell>{row.valueQuantityCode}</TableCell>

                    <TableCell>{row.valueQuantityReferenceRangeLow}</TableCell>

                    <TableCell>{row.valueQuantityReferenceRangeHigh}</TableCell>

                    <TableCell>{row.valueQuantityReferenceRangeUnit}</TableCell>

                    <TableCell>{row.valueQuantityReferenceRangeSystem}</TableCell>

                    <TableCell>{row.valueQuantityReferenceRangeCode}</TableCell>

                    <TableCell>{row.valueQuantityReferenceRangeText}</TableCell>

                  </TableRow>

                </TableBody>

              </Table>

            </Box>

          </Collapse>

        </TableCell>

      </TableRow>
    </>
  );
};

export default ObservationRow;
