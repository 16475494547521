import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { ConditionsFilter, conditionsFilterSearchParams } from 'common/types/SearchParams';
import { HeadCell } from 'common/types/MuiComponents';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import DataTable from 'common/components/DataTable/DataTable';
import { formatDate } from 'common/helpers/dateTime';
import useGetConditions from 'components/AdminDashboard/components/EHRViewerPage/Conditions/hooks';

import React, { useCallback, useMemo } from 'react';
import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const Conditions: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { conditions, getConditions, isLoading, error, pagination } = useGetConditions(userId!);
  const { params } = useFilterSearchParams<ConditionsFilter>(conditionsFilterSearchParams);

  const fetchConditions = useCallback((abortController: AbortController) => {
    getConditions(params, abortController);
  }, [ getConditions, params ]);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'status',
      numeric: false,
      label: 'Status',
    },
    {
      id: 'startDate',
      numeric: false,
      label: 'Onset start',
    },
    {
      id: 'endDate',
      numeric: false,
      label: 'Onset end',
    },
    {
      id: 'codeCode',
      numeric: false,
      label: 'Code',
    },
    {
      id: 'codeSystem',
      numeric: false,
      label: 'Code system',
    },
    {
      id: 'codeDisplay',
      numeric: false,
      label: 'Code display',
    },
    {
      id: 'categoryCode',
      numeric: false,
      label: 'Category code',
    },
    {
      id: 'categorySystem',
      numeric: false,
      label: 'Category system',
    },
    {
      id: 'categoryDisplay',
      numeric: false,
      label: 'Category display',
    },
  ], []);

  const renderRows = useCallback(() => conditions.map(row => (
    <TableRow hover tabIndex={-1} key={row.conditionId}>

      <TableCell>{row.conditionStatus}</TableCell>

      <TableCell>{formatDate(row.conditionOnsetStartDate)}</TableCell>

      <TableCell>{formatDate(row.conditionOnsetEndDate)}</TableCell>

      <TableCell>{row.codeCode}</TableCell>

      <TableCell>{row.codeSystem}</TableCell>

      <TableCell>{row.codeDisplay}</TableCell>

      <TableCell>{row.categoryCode}</TableCell>

      <TableCell>{row.categorySystem}</TableCell>

      <TableCell>{row.categoryDisplay}</TableCell>

    </TableRow>
  )), [ conditions ]);

  return (
    <LoadingContainer isLoading={isLoading}>

      {error && (
        <Grid item xs={12} padding={2}>
          <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
            {error}
          </Typography>
        </Grid>
      )}

      <DataTable
        pagination={pagination}
        headCells={headCells}
        renderRows={renderRows}
        updateDataCallback={fetchConditions}
      />

    </LoadingContainer>
  );
};

export default Conditions;
