import { SearchParams } from 'common/types/SearchParams';

import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

const usePaginationSearchParams = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const pageParam = searchParams.get(SearchParams.PAGE) || '1';
  const rowsParam = searchParams.get(SearchParams.ROWS) || '10';
  const usernameParam = searchParams.get(SearchParams.USERNAME) || '';

  const setPageParam = useCallback((page: string) => {
    searchParams.set(SearchParams.PAGE, page);
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const setRowsParam = useCallback((rows: string) => {
    searchParams.set(SearchParams.ROWS, rows);
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const setUsernameParam = useCallback((username: string) => {
    if (username !== '') {
      searchParams.set(SearchParams.USERNAME, username);
    } else {
      searchParams.delete(SearchParams.USERNAME);
    }
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  return {
    pageParam,
    rowsParam,
    usernameParam,
    setPageParam,
    setRowsParam,
    setUsernameParam,
  };
};

export default usePaginationSearchParams;
