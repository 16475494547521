import { useCurrentTab } from 'containers/hooks/useCurrentTab';
import { SamplesTab } from 'containers/types/tabs';
import SamplesControlPanel from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/SamplesControlPanel';
import UsersSamples from 'components/AdminDashboard/components/SamplesPage/UsersSamples';
import Samples from 'components/AdminDashboard/components/SamplesPage/Samples';
import Bags from 'components/AdminDashboard/components/SamplesPage/Bags';
import Plates from 'components/AdminDashboard/components/SamplesPage/Plates';

import React from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { Box, Paper, Tab, Tabs } from '@mui/material';

const SamplesContainer: React.FC = () => {
  const tabs = Object.values(SamplesTab);
  const currentTab = useCurrentTab(tabs) as SamplesTab;

  return (
    <Box data-testid="samples-container">
      <SamplesControlPanel currentTab={currentTab} />

      <Paper sx={{ maxWidth: '100%' }}>
        <Tabs value={currentTab} indicatorColor="secondary" textColor="secondary" sx={{ borderTopLeftRadius: '4px' }}>
          <Tab label="Users’ Samples" value={tabs[0]} to={tabs[0]} component={Link} />
          <Tab label="Samples" value={tabs[1]} to={tabs[1]} component={Link} />
          <Tab label="Bags" value={tabs[2]} to={tabs[2]} component={Link} />
          <Tab label="Plates" value={tabs[3]} to={tabs[3]} component={Link} />
        </Tabs>
        <Routes>
          <Route path={tabs[0]} element={<UsersSamples />} />
          <Route path={tabs[1]} element={<Samples />} />
          <Route path={tabs[2]} element={<Bags />} />
          <Route path={tabs[3]} element={<Plates />} />
          <Route path="/*" element={<Navigate to={tabs[0]} />} />
        </Routes>
      </Paper>
    </Box>
  );
};

export default SamplesContainer;
