export enum SamplesTab {
  USERS_SAMPLES = 'users-samples',
  SAMPLES = 'samples',
  BAGS = 'bags',
  PLATES = 'plates',
}

export enum EHRViewerTab {
  CONDITIONS = 'conditions',
  MEDICATIONS = 'medications',
  OBSERVATIONS = 'observations',
}
