import { exportUserReportsRequest } from 'api/Reports/api';
import { FileFormat } from 'api/User/types';
import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { ReportsFilter, reportsFilterSearchParams } from 'common/types/SearchParams';
import { downloadCSVFile } from 'common/helpers/downloadCSVFile';

import { useCallback } from 'react';

export const useExportReports = (setIsExporting: (exporting: boolean) => void, onFinish: () => void) => {
  const { params } = useFilterSearchParams<ReportsFilter>(reportsFilterSearchParams);

  const exportUserReports = useCallback((fileFormat: FileFormat) => {
    setIsExporting(true);

    exportUserReportsRequest(params, fileFormat)
      .then((response) => {
        downloadCSVFile('exported_reports', response.data, fileFormat);
      })
      .finally(() => {
        setIsExporting(false);
        onFinish();
      });
  }, [
    params,
    setIsExporting,
    onFinish,
  ]);

  return { exportUserReports };
};
