import { FileFormat } from 'api/User/types';
import { useExportReports } from 'components/AdminDashboard/components/ReportsPage/components/ExportReportsPopover/hooks';

import React from 'react';
import { Menu, MenuItem } from '@mui/material';

interface Props {
  setIsExporting: (exporting: boolean) => void;
  onClose: () => void;
  data: Element | null;
}

const ExportReportsPopover: React.FC<Props> = ({ data, onClose, setIsExporting }) => {
  const { exportUserReports } = useExportReports(setIsExporting, onClose);

  return (
    <Menu
      open={Boolean(data)}
      anchorEl={data}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuItem onClick={() => exportUserReports(FileFormat.CSV)} sx={{ fontSize: '14px' }}>
        Export as csv
      </MenuItem>
      <MenuItem onClick={() => exportUserReports(FileFormat.TSV)} sx={{ fontSize: '14px' }}>
        Export as tsv
      </MenuItem>
    </Menu>
  );
};

export default ExportReportsPopover;
