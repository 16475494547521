import { getUserKits } from 'api/User/api';
import { WithKit } from 'api/User/types';
import { getTransactionsOverview } from 'api/Transactions/api';
import { OVERVIEW_INITIAL_STATE } from 'api/Transactions/constants';
import { Overview } from 'api/Transactions/types';
import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { Pagination } from 'common/types/Pagination';

import { useCallback, useState } from 'react';
import omit from 'lodash/omit';

export const useUserKits = () => {
  const { pageParam, rowsParam, usernameParam } = usePaginationSearchParams();
  const [ userKits, setUserKits ] = useState<WithKit[]>([]);
  const [ pagination, setPagination ] = useState<Pagination | undefined>();
  const [ transactionsOverview, setTransactionsOverview ] = useState<Overview>(OVERVIEW_INITIAL_STATE);
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);
  const [ isOverviewLoading, setOverviewLoading ] = useState(false);

  const requestUserKits = useCallback(() => {
    setLoading(true);
    setError('');
    getUserKits(pageParam, rowsParam, usernameParam)
      .then(({ data }) => {
        if (data) {
          setUserKits(data.results);
          setPagination(omit(data, 'results'));
        }
      })
      .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setLoading(false));
  }, [ pageParam, rowsParam, usernameParam ]);

  const requestTransactionsOverview = useCallback(() => {
    setOverviewLoading(true);
    setError('');
    getTransactionsOverview()
      .then(({ data }) => {
        if (data) {
          setTransactionsOverview(data);
        }
      })
      .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setOverviewLoading(false));
  }, []);

  return {
    requestUserKits,
    requestTransactionsOverview,
    userKits,
    transactionsOverview,
    pagination,
    setUserKits,
    isOverviewLoading,
    isLoading,
    error,
  };
};
