import { getMedicationsFilterDataRequest } from 'api/FHIR/api';
import { MedicationsFilterData } from 'api/FHIR/types';

import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useGetFilterData = () => {
  const { t } = useTranslation();

  const [ filterData, setFilterData ] = useState<MedicationsFilterData>({ systems: [], statuses: [] });
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>('');

  const getFilterData = useCallback((abortController: AbortController) => {
    setIsLoading(true);
    setError('');

    getMedicationsFilterDataRequest(abortController)
      .then((response) => {
        setFilterData(response.data);
      })
      .catch((error) => {
        if (!abortController?.signal.aborted) {
          setError(error?.response?.data?.message || t('errors.server_error_try_again'));
        }
      })
      .finally(() => {
        if (!abortController?.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, [ t ]);

  return { getFilterData, filterData, isLoading, error };
};

export default useGetFilterData;
