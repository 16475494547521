import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { medicationsFilterSearchParams, MedicationsFilter } from 'common/types/SearchParams';

import { useFormik } from 'formik';

const useMedicationsFilter = (onSubmit: () => void) => {
  const { params, setFilter, clearFilter } = useFilterSearchParams<MedicationsFilter>(medicationsFilterSearchParams);

  const formikSubmit = (params: MedicationsFilter) => {
    setFilter(params);
    onSubmit();
  };

  const formik = useFormik<MedicationsFilter>({
    initialValues: params,
    onSubmit: formikSubmit,
    enableReinitialize: true,
  });

  const clearFormAndSearchParams = () => {
    formik.resetForm();
    clearFilter();
  };

  return { formik, clearFormAndSearchParams };
};

export default useMedicationsFilter;
