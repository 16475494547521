import { ReduxDispatch } from 'store';
import { getSamplesRequest, getBagsRequest, getPlatesRequest } from 'api/Samples/api';
import { SET_SAMPLES, SET_BAGS, SET_PLATES, SET_SAMPLES_LOADING, SET_SAMPLES_ERROR } from 'store/samples/types';

import i18next from 'i18next';

export const getSamples = (page: string, pageSize: string) => (dispatch: ReduxDispatch) => {
  dispatch({ type: SET_SAMPLES_LOADING, value: true });

  getSamplesRequest(page, pageSize)
    .then(({ data }) => {
      dispatch({ type: SET_SAMPLES, data: data || {} });
    })
    .catch((error) => {
      const value = error?.response?.data?.message || i18next.t(('errors.server_error_try_again'));

      dispatch({ type: SET_SAMPLES_ERROR, value });
    })
    .finally(() => {
      dispatch({ type: SET_SAMPLES_LOADING, value: false });
    });
};

export const getBags = (page: string, pageSize: string) => (dispatch: ReduxDispatch) => {
  dispatch({ type: SET_SAMPLES_LOADING, value: true });

  getBagsRequest(page, pageSize)
    .then(({ data }) => {
      dispatch({ type: SET_BAGS, data: data || {} });
    })
    .catch((error) => {
      const value = error?.response?.data?.message || i18next.t(('errors.server_error_try_again'));

      dispatch({ type: SET_SAMPLES_ERROR, value });
    })
    .finally(() => {
      dispatch({ type: SET_SAMPLES_LOADING, value: false });
    });
};

export const getPlates = (page: string, pageSize: string) => (dispatch: ReduxDispatch) => {
  dispatch({ type: SET_SAMPLES_LOADING, value: true });

  getPlatesRequest(page, pageSize)
    .then(({ data }) => {
      dispatch({ type: SET_PLATES, data: data || {} });
    })
    .catch((error) => {
      const value = error?.response?.data?.message || i18next.t(('errors.server_error_try_again'));

      dispatch({ type: SET_SAMPLES_ERROR, value });
    })
    .finally(() => {
      dispatch({ type: SET_SAMPLES_LOADING, value: false });
    });
};
