import { postVerifyAccount } from 'api/Auth/api';
import { LOGIN_SUCCESS } from 'store/auth/types';
import { AmplitudeEvent } from 'common/constants/amplitude';
import { DASHBOARD_ROUTE } from 'common/constants/urls';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';

const useVerify = (key?: string) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logEvent } = useAmplitude();

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isError, setIsError ] = useState<boolean>(false);

  useEffect(() => {
    if (!key) {
      navigate(DASHBOARD_ROUTE);
    }
  }, [ key, navigate ]);

  const verifyAccount = useCallback(() => {
    setIsLoading(true);

    postVerifyAccount(key!)
      .then(({ data: { token, refreshToken } }) => {
        dispatch({ type: LOGIN_SUCCESS, token, refreshToken });
        logEvent(AmplitudeEvent.ACCOUNT_ACTIVATED);
        navigate(DASHBOARD_ROUTE);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ dispatch, logEvent, navigate, key ]);

  return { isLoading, isError, verifyAccount };
};

export default useVerify;
