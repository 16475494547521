import { axiosInstance } from 'api/instance';
import { Organization } from 'api/Organizations/types';
import PaginatedData from 'common/types/PaginatedData';

import { AxiosResponse } from 'axios';

export const getOrganizationsRequest = (page: string, pageSize: string): Promise<AxiosResponse<PaginatedData<Organization>>> => {
  return axiosInstance.get<PaginatedData<Organization>>(`organization?page=${page}&pageSize=${pageSize}`);
};

export const createOrganizationRequest = (org: Organization): Promise<AxiosResponse<Organization>> => {
  const { id, ...rest } = org;
  return axiosInstance.post('organization', rest);
};

export const changeOrganizationRequest = (org: Organization): Promise<AxiosResponse<Organization>> => {
  const { id, sites, ...rest } = org;
  const filteredSites = sites.filter(site => site.name);
  return axiosInstance.put(`organization/${id}`, {
    ...rest,
    sites: filteredSites,
  });
};
