import { Organization } from 'api/Organizations/types';
import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { HeadCell } from 'common/types/MuiComponents';
import DataTable from 'common/components/DataTable/DataTable';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import OrganizationModal from 'components/AdminDashboard/components/OrganizationsPage/components/OrganizationModal/OrganizationModal';
import { getOrganizations } from 'store/organizations/actions';
import { RootState } from 'store';

import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, IconButton, Paper, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';

const OrganizationsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { pageParam, rowsParam } = usePaginationSearchParams();

  const fetchOrganizations = useCallback(() => {
    dispatch(getOrganizations(pageParam, rowsParam));
  }, [ dispatch, pageParam, rowsParam ]);

  const { organizations, pagination, isLoading } = useSelector((store: RootState) => store.organizations);

  const [ orgModal, setOrgModal ] = useState<Organization | null | undefined>();

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'organizationId',
      numeric: false,
      label: 'Organization id',
    },
    {
      id: 'organization',
      numeric: false,
      label: 'Name',
    },
    {
      id: 'email',
      numeric: false,
      label: 'Email',
    },
    {
      id: 'sites',
      numeric: false,
      label: 'Sites',
    },
    {
      id: 'change',
      numeric: false,
      label: '',
    },
  ], []);

  const renderRows = useCallback(() => organizations
    .map((row: Organization) => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell>
          <Typography variant="textSRegular">
            {row.id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="textSRegular">
            {row.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="textSRegular">
            {row.primaryEmail}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="textSRegular">
            {row.sites.reduce((result, cur): string[] => {
              return [ ...result, cur.name ];
            }, [] as string[]).join(', ')}
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip title="Edit">
            <IconButton color="primary" size="small" onClick={() => setOrgModal(row)}>
              <Edit fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    )), [ organizations ]);

  return (
    <LoadingContainer isLoading={isLoading}>

      <Box data-testid="organizations-page">

        <Box mb={2} display="flex" flexDirection="row-reverse">
          <Button
            sx={{
              backgroundColor: 'white',
              textTransform: 'none',
            }}
            variant="text"
            onClick={() => setOrgModal(null)}
          >
            Add Organization
          </Button>
        </Box>

        <Paper sx={{ maxWidth: '100%' }}>
          <DataTable
            pagination={pagination}
            headCells={headCells}
            renderRows={renderRows}
            updateDataCallback={fetchOrganizations}
          />
        </Paper>

        {orgModal !== undefined && <OrganizationModal onCancel={() => setOrgModal(undefined)} org={orgModal} />}

      </Box>

    </LoadingContainer>
  );
};

export default OrganizationsPage;
