import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { HeadCell } from 'common/types/MuiComponents';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import DataTable from 'common/components/DataTable/DataTable';
import { formatDateTime } from 'common/helpers/dateTime';
import { getPlates } from 'store/samples/actions';
import { RootState } from 'store';

import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TableCell, TableRow, Typography } from '@mui/material';

const Plates: React.FC = () => {
  const dispatch = useDispatch();
  const { pageParam, rowsParam } = usePaginationSearchParams();

  const fetchPlates = useCallback(() => {
    dispatch(getPlates(pageParam, rowsParam));
  }, [ dispatch, pageParam, rowsParam ]);

  const { plates, pagination, isLoading, error } = useSelector((store: RootState) => store.samples);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'plateId',
      numeric: false,
      label: 'Plate id',
    },
    {
      id: 'plate',
      numeric: false,
      label: 'Plate',
    },
    {
      id: 'createdAt',
      numeric: false,
      label: 'Created at',
    },
  ], []);

  const renderRows = useCallback(() => plates
    .map((row) => {
      return (
        <TableRow hover tabIndex={-1} key={row.id}>
          <TableCell>
            <Typography variant="textSRegular">
              {row.id}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.plateId}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {formatDateTime(row.createdAt)}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }), [ plates ]);

  return (
    <LoadingContainer isLoading={isLoading}>

      { error && (
        <Grid item xs={12} padding={2}>
          <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
            {error}
          </Typography>
        </Grid>
      )}

      <DataTable
        pagination={pagination}
        headCells={headCells}
        renderRows={renderRows}
        updateDataCallback={fetchPlates}
      />
    </LoadingContainer>
  );
};

export default Plates;
