import { addUserReportRequest } from 'api/Reports/api';
import { Ancestry, UserReportRequest } from 'api/Reports/types';

import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

export interface UploadAncestryFormValues {
  source: string;
  file: File | null;
}

const useUploadAncestryReport = (userId: number, onUpdated: (userId: number, ancestries: Ancestry[]) => void) => {
  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  const validationSchema = useMemo(() => yup.object({
    source: yup
      .string()
      .required('Source is required'),
    file: yup
      .string()
      .typeError('File is required')
      .required('File is required'),
  }), []);

  const formikSubmit = ({ source, file }: UploadAncestryFormValues) => {
    setSubmitting(true);
    setSubmitError('');

    const data = new FormData();
    if (file) {
      data.append('uploadedFile', file);
    }

    const request: UserReportRequest = { userId, source, file: data };

    addUserReportRequest(request)
      .then(({ data }) => {
        onUpdated(userId, data);
      })
      .catch(error => setSubmitError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    source: '',
    file: null,
  };

  const formik = useFormik<UploadAncestryFormValues>({
    initialValues,
    validationSchema,
    onSubmit: formikSubmit,
  });

  return { formik, isSubmitting, submitError };
};

export default useUploadAncestryReport;
