import { AuthorizationContainer } from 'common/components';
import useVerify from 'components/Dashboard/components/Authorization/hooks/useVerify';
import ResendLink from 'components/Dashboard/components/Authorization/Verify/components/ResendLink/ResendLink';

import React from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';

const VerifyPage = () => {
  const { t } = useTranslation();

  const { key } = useParams();
  const { isLoading, isError, verifyAccount } = useVerify(key);

  return (
    <AuthorizationContainer data-testid="verify-page">

      {!isError ? (
        <Box display="flex" justifyContent="flex-start" flexDirection="column" width="100%">
          <Typography marginTop="0" marginBottom={{ xs: 8, sm: 5 }}>
            {t('verify_page.activate_account')}
          </Typography>

          <LoadingButton
            sx={{ width: { xs: '100%', sm: 'unset' } }}
            loading={isLoading}
            disabled={isLoading}
            onClick={verifyAccount}
            variant="contained"
            data-testid="activate-button"
          >
            {t('verify_page.button_activate')}
          </LoadingButton>
        </Box>
      ) : <ResendLink />}

    </AuthorizationContainer>
  );
};

export default VerifyPage;
