import { AncestryFile } from 'api/User/types';
import { ShippingStatus } from 'api/Shipment/types';
import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { ReportsFilter, reportsFilterSearchParams } from 'common/types/SearchParams';
import SearchUserInput from 'common/components/SearchUserInput/SearchUserInput';
import { useUserReports } from 'components/AdminDashboard/components/ReportsPage/hooks';
import { isDataScienceAdmin } from 'common/helpers/user';
import { getUserAncestryFile } from 'api/Reports/api';
import { Ancestry, UserReport } from 'api/Reports/types';
import { groupAncestryFilesByProvider } from 'components/AdminDashboard/components/ReportsPage/helpers';
import AncestryPopover, { Data as AncestryPopoverData }
  from 'components/AdminDashboard/components/ReportsPage/components/AncestryPopover/AncestryPopover';
import AncestryFilesPopover, { Data as AncestryFilesPopoverData }
  from 'components/AdminDashboard/components/ReportsPage/components/AncestryFilesPopover/AncestryFilesPopover';
import UploadReportPopover, { Data as UploadPopoverData }
  from 'components/AdminDashboard/components/ReportsPage/components/UploadReportPopover/UploadReportPopover';
import ExportReportsPopover from 'components/AdminDashboard/components/ReportsPage/components/ExportReportsPopover/ExportReportsPopover';
import ReportsFilterPopover from 'components/AdminDashboard/components/ReportsPage/components/ReportsFilterPopover/ReportsFilterPopover';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import { isDeliveredStatus } from 'common/helpers/shipping';
import DataTable from 'common/components/DataTable/DataTable';
import { HeadCell } from 'common/types/MuiComponents';
import { isFilterUsed } from 'common/helpers/filters';
import { RootState } from 'store';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import map from 'lodash/map';
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Grid, IconButton, Paper, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import FilterAlt from '@mui/icons-material/FilterAlt';
import FilterAltOffOutlined from '@mui/icons-material/FilterAltOffOutlined';
import Download from '@mui/icons-material/Download';

const ReportsPage: React.FC = () => {
  const { params } = useFilterSearchParams<ReportsFilter>(reportsFilterSearchParams);
  const { myUser } = useSelector((store: RootState) => store.user);
  const isUserDataScienceAdmin = isDataScienceAdmin(myUser);

  const {
    requestUserReports,
    userReports,
    pagination,
    setUserReports,
    isLoading,
    error,
  } = useUserReports();

  const [ searchValue, setSearchValue ] = useState(params.username);
  const [ isExporting, setIsExporting ] = useState<boolean>(false);

  const fetchReports = useCallback((abortController: AbortController) => {
    requestUserReports(params, abortController);
  }, [ requestUserReports, params ]);

  const handleReportUpdated = useCallback((userId: number, ancestries: Ancestry[]) => {
    const userIndex = userReports.findIndex(item => item.id === userId);

    setUserReports([
      ...userReports.slice(0, userIndex),
      { ...userReports[userIndex], ancestries },
      ...userReports.slice(userIndex + 1, userReports.length),
    ]);

    setUploadPopoverData(null);
  }, [ setUserReports, userReports ]);

  const [ uploadPopoverData, setUploadPopoverData ] = useState<UploadPopoverData | null>(null);
  const [ ancestryPopoverData, setAncestryPopoverData ] = useState<AncestryPopoverData | null>(null);
  const [ ancestryFilesPopoverData, setAncestryFilesPopoverData ] = useState<AncestryFilesPopoverData | null>(null);

  const [ filterPopoverEl, setFilterPopoverEl ] = useState<HTMLButtonElement | null>(null);
  const onFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => setFilterPopoverEl(event.currentTarget);
  const onFilterPopoverClose = () => setFilterPopoverEl(null);

  const [ exportPopoverEl, setExportPopoverEl ] = useState<HTMLButtonElement | null>(null);
  const onExportButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => setExportPopoverEl(event.currentTarget);
  const onExportPopoverClose = () => setExportPopoverEl(null);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'userId',
      numeric: false,
      label: 'User id',
    },
    {
      id: 'user',
      numeric: false,
      label: !isUserDataScienceAdmin ? 'User' : '',
      button: <SearchUserInput onChange={e => setSearchValue(e.target.value)} hasLeftPadding={!isUserDataScienceAdmin} />,
    },
    {
      id: 'dna',
      numeric: false,
      label: 'DNA Uploaded',
    },
    {
      id: 'dnaSample',
      numeric: false,
      label: 'DNA Sample',
    },
    {
      id: 'ancestryReport',
      numeric: true,
      label: 'Ancestry Report',
    },
    {
      id: 'prsReport',
      numeric: true,
      label: 'PRS Report',
    },
    {
      id: 'empty',
      numeric: true,
      label: '',
    },
  ], [ isUserDataScienceAdmin ]);

  const renderAncestryFiles = (ancestryFiles: AncestryFile[], userId: number) => {
    const filesGroupedByProvider = groupAncestryFilesByProvider(ancestryFiles, userId);

    return map(filesGroupedByProvider, (links, providerName) => {
      const onClick = links.length === 1 ?
        () => getUserAncestryFile(links[0].id, links[0].fileName) :
        (e: React.SyntheticEvent) => setAncestryFilesPopoverData({ anchorEl: e.target as HTMLElement, links, userId });

      return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} key={providerName}>
          <Typography variant="textSRegular">
            {providerName}
          </Typography>
          <IconButton onClick={onClick}>
            <CloudDownloadIcon />
          </IconButton>
        </Stack>
      );
    });
  };

  const getShipmentStatus = (returnShipment?: ShippingStatus) => {
    if (!returnShipment) {
      return 'Not started';
    }
    if (isDeliveredStatus(returnShipment.status)) {
      return 'Received';
    }
    return 'Requested';
  };

  const getAncestryReportStatus = (user: UserReport) => {
    if (!user.ancestries.length) {
      return (
        <Typography variant="textSRegular">
          Pending
        </Typography>
      );
    }

    return (
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Typography variant="textSRegular">
          Generated
        </Typography>
        <IconButton onClick={e => setAncestryPopoverData({ anchorEl: e.target as HTMLElement, ancestries: user.ancestries })}>
          <VisibilityIcon />
        </IconButton>
      </Stack>
    );
  };

  const renderRows = useCallback(() => userReports.map(row => (
    <TableRow hover tabIndex={-1} key={row.id}>
      <TableCell>
        <Typography variant="textSRegular">
          { row.id }
        </Typography>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        padding="normal"
      >
        {!isUserDataScienceAdmin && (
          <>
            <Typography variant="textSRegular" component="div">
              { `${row.firstName} ${row.lastName}` }
            </Typography>
            <Typography variant="textSRegular" component="div">
              { row.username }
            </Typography>
          </>
        )}
      </TableCell>
      <TableCell align="center">
        {row.ancestryFiles.length > 0 && renderAncestryFiles(row.ancestryFiles, row.id)}
      </TableCell>
      <TableCell align="center">
        <Typography variant="textSRegular">
          {getShipmentStatus(row.returnShippingStatus)}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {getAncestryReportStatus(row)}
      </TableCell>
      <TableCell align="right">
        <Typography variant="textSRegular">
          Pending
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Tooltip title="More options">
          <IconButton onClick={e => setUploadPopoverData({ anchorEl: e.target as HTMLElement, userId: row.id, files: row.ancestryFiles })}>
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )), [ userReports, isUserDataScienceAdmin ]);

  return (
    <Box data-testid="reports-page">

      <Box display="flex" justifyContent="space-between" sx={{ marginBottom: 2 }}>

        <Button
          sx={{
            backgroundColor: 'white',
            width: '102px',
            textTransform: 'none',
          }}
          variant="text"
          onClick={onFilterButtonClick}
          endIcon={isFilterUsed(params) ? <FilterAlt /> : <FilterAltOffOutlined />}
        >
          Filters
        </Button>

        <ReportsFilterPopover data={filterPopoverEl} onClose={onFilterPopoverClose} />

        <Button
          sx={{
            backgroundColor: 'white',
            width: '102px',
            textTransform: 'none',
          }}
          variant="text"
          onClick={onExportButtonClick}
          endIcon={<Download />}
          disabled={isExporting}
        >
          Export
        </Button>

        <ExportReportsPopover data={exportPopoverEl} onClose={onExportPopoverClose} setIsExporting={setIsExporting} />

      </Box>

      <Paper sx={{ maxWidth: '100%' }}>

        <LoadingContainer isLoading={isLoading}>

          {error && (
            <Grid item xs={12} padding={2}>
              <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
                {error}
              </Typography>
            </Grid>
          )}

          <DataTable
            pagination={pagination}
            headCells={headCells}
            renderRows={renderRows}
            searchValue={searchValue}
            updateDataCallback={fetchReports}
          />

        </LoadingContainer>

      </Paper>

      {uploadPopoverData && (
        <UploadReportPopover
          data={uploadPopoverData}
          onUpdate={handleReportUpdated}
          onClose={() => setUploadPopoverData(null)}
        />
      )}

      {ancestryPopoverData && (
        <AncestryPopover
          data={ancestryPopoverData}
          onClose={() => setAncestryPopoverData(null)}
        />
      )}

      <AncestryFilesPopover
        data={ancestryFilesPopoverData}
        onClose={() => setAncestryFilesPopoverData(null)}
      />

    </Box>
  );
};

export default ReportsPage;
