import { axiosInstance } from 'api/instance';
import { Condition, ConditionsFilterData, Observation, ObservationsFilterData, MedicationStatement, MedicationsFilterData } from 'api/FHIR/types';
import { getFiltersUrlString } from 'common/helpers/filters';
import PaginatedData from 'common/types/PaginatedData';
import { ConditionsFilter, MedicationsFilter, ObservationsFilter } from 'common/types/SearchParams';

import { AxiosResponse } from 'axios';

export const getConditionsRequest = (
  userId: string,
  filters: ConditionsFilter,
  abortController: AbortController,
): Promise<AxiosResponse<PaginatedData<Condition>>> => {
  return axiosInstance.get<PaginatedData<Condition>>(
    `fhir/condition?page=${filters.page}&pageSize=${filters.pageSize}&bbofaUserId=${userId}${getFiltersUrlString(filters)}`,
    { signal: abortController?.signal },
  );
};

export const getConditionsFilterDataRequest = ({ signal }: AbortController): Promise<AxiosResponse<ConditionsFilterData>> => {
  return axiosInstance.get<ConditionsFilterData>('fhir/condition/filter-data', { signal });
};

export const getObservationsRequest = (
  userId: string,
  filters: ObservationsFilter,
  abortController: AbortController,
): Promise<AxiosResponse<PaginatedData<Observation>>> => {
  return axiosInstance.get<PaginatedData<Observation>>(
    `fhir/observation?page=${filters.page}&pageSize=${filters.pageSize}&bbofaUserId=${userId}${getFiltersUrlString(filters)}`,
    { signal: abortController?.signal },
  );
};

export const getObservationFilterDataRequest = ({ signal }: AbortController): Promise<AxiosResponse<ObservationsFilterData>> => {
  return axiosInstance.get<ObservationsFilterData>('fhir/observation/filter-data', { signal });
};

export const getMedicationsRequest = (
  userId: string,
  filters: MedicationsFilter,
  abortController: AbortController,
): Promise<AxiosResponse<PaginatedData<MedicationStatement>>> => {
  return axiosInstance.get<PaginatedData<MedicationStatement>>(
    `fhir/medication-statement?page=${filters.page}&pageSize=${filters.pageSize}&bbofaUserId=${userId}${getFiltersUrlString(filters)}`,
    { signal: abortController?.signal },
  );
};

export const getMedicationsFilterDataRequest = ({ signal }: AbortController): Promise<AxiosResponse<MedicationsFilterData>> => {
  return axiosInstance.get<MedicationsFilterData>('fhir/medication-statement/filter-data', { signal });
};
