export enum SearchParams {
  PAGE = 'page',
  ROWS = 'pageSize',
  USERNAME = 'username',
}

export const dataTableSearchParams = [ SearchParams.PAGE, SearchParams.ROWS, SearchParams.USERNAME ] as const;

export const usersFilterSearchParams = [
  ...dataTableSearchParams,
  'isNotDefaultOnly',
  'status',
  'testKitShipped',
  'gender',
  'organizationIds',
  'ehrStatus',
  'freezerworksSyncStatus',
  'isSurveyCompleted',
] as const;

export type UsersFilter = {
  [k in typeof usersFilterSearchParams[number]]?: string;
};

export const reportsFilterSearchParams = [
  ...dataTableSearchParams,
  'ancestryReportStatus',
  'prsReportStatus',
  'dnaUploadedStatus',
  'dnaSampleStatus',
  'organizationIds',
] as const;

export type ReportsFilter = {
  [k in typeof reportsFilterSearchParams[number]]?: string;
};

export const conditionsFilterSearchParams = [
  ...dataTableSearchParams,
  'status',
  'system',
  'code',
  'startDate',
  'endDate',
] as const;

export type ConditionsFilter = {
  [k in typeof conditionsFilterSearchParams[number]]?: string;
};

export const medicationsFilterSearchParams = [
  ...dataTableSearchParams,
  'status',
  'system',
  'code',
  'startDate',
  'endDate',
  'minDosageValue',
  'maxDosageValue',
] as const;

export type MedicationsFilter = {
  [k in typeof medicationsFilterSearchParams[number]]?: string;
};

export const observationsFilterSearchParams = [
  ...dataTableSearchParams,
  'status',
  'code',
  'categoryCode',
  'startDate',
  'endDate',
  'minValueInteger',
  'maxValueInteger',
  'minValueQuantity',
  'maxValueQuantity',
] as const;

export type ObservationsFilter = {
  [k in typeof observationsFilterSearchParams[number]]?: string;
};
