import { getUsersRequest, getEHRExecutions } from 'api/User/api';
import { PipelineExecutionResponseMessage } from 'api/User/types';
import { UsersFilter } from 'common/types/SearchParams';
import { ReduxDispatch } from 'store';
import { PipelineExecutionValues } from 'store/users/models';
import {
  SET_USERS,
  SET_USERS_LOADING,
  SET_USERS_ERROR,
  GET_USER_PIPELINE_EXECUTION,
} from 'store/users/types';

import i18next from 'i18next';
import omit from 'lodash/omit';

export const getUsers = (
  filters: UsersFilter,
  clinicalPatients = false,
  abortController?: AbortController,
) => (dispatch: ReduxDispatch) => {
  dispatch({ type: SET_USERS_LOADING, value: true });

  getUsersRequest(filters, clinicalPatients, abortController)
    .then(({ data }) => {
      const pagination = omit(data, 'results');
      dispatch({ type: SET_USERS, users: data.results, pagination });
    })
    .catch((error) => {
      const value = error?.response?.data?.message || i18next.t(('errors.server_error_try_again'));

      if (!abortController?.signal.aborted) {
        dispatch({ type: SET_USERS_ERROR, value });
      }
    })
    .finally(() => {
      if (!abortController?.signal.aborted) {
        dispatch({ type: SET_USERS_LOADING, value: false });
      }
    });
};

export const getUserPipelineExecution = (userId: number, finalCallback: () => void) => (dispatch: ReduxDispatch) => {
  getEHRExecutions(userId)
    .then((response) => {
      const { message, responseMessage } = response.data;
      let error = message;

      if (responseMessage !== undefined) {
        try {
          const parsedResponseMessage = JSON.parse(responseMessage) as PipelineExecutionResponseMessage;
          const issue = parsedResponseMessage.issue[0];
          error = issue.diagnostics || issue.details?.text || message;
        } catch {
          // just skip this block and use message as error
        }
      }

      const data: PipelineExecutionValues = { userId, error };

      dispatch({ type: GET_USER_PIPELINE_EXECUTION, data });
    }).finally(() => {
      finalCallback();
    });
};
