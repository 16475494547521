import { AncestryFileLink } from 'api/Reports/types';
import { getUserAncestryFile } from 'api/Reports/api';

import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import map from 'lodash/map';

export interface Data {
  anchorEl: Element;
  links: AncestryFileLink[];
  userId: number;
}

interface Props {
  onClose: () => void;
  data: Data | null;
}

const AncestryFilesPopover: React.FC<Props> = ({ data, onClose }) => {
  return (
    <Menu
      open={Boolean(data)}
      anchorEl={data?.anchorEl}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {map(data?.links, (fileLink) => {
        return (
          <MenuItem
            onClick={() => getUserAncestryFile(fileLink.id, fileLink.fileName)}
            sx={{ fontSize: '14px' }}
            key={fileLink.id}
          >
            {fileLink.sourceFileUploadedAt || 'No date'}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default AncestryFilesPopover;
