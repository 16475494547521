import { EHRViewerTab } from 'containers/types/tabs';
import ConditionsFilterPopover
  from 'components/AdminDashboard/components/EHRViewerPage/EHRViewerControlPanel/components/ConditionsFilterPopover/ConditionsFilterPopover';
import MedicationsFilterPopover
  from 'components/AdminDashboard/components/EHRViewerPage/EHRViewerControlPanel/components/MedicationsFilterPopover/MedicationsFilterPopover';
import ObservationsFilterPopover
  from 'components/AdminDashboard/components/EHRViewerPage/EHRViewerControlPanel/components/ObservationsFilterPopover/ObservationsFilterPopover';
import { isFilterUsed } from 'common/helpers/filters';
import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import {
  ConditionsFilter,
  conditionsFilterSearchParams,
  MedicationsFilter,
  medicationsFilterSearchParams,
  ObservationsFilter,
  observationsFilterSearchParams,
} from 'common/types/SearchParams';

import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import FilterAlt from '@mui/icons-material/FilterAlt';
import FilterAltOffOutlined from '@mui/icons-material/FilterAltOffOutlined';

interface Props {
  currentTab: EHRViewerTab;
}

const EHRViewerControlPanel: React.FC<Props> = ({ currentTab }) => {
  const [ filterPopoverEl, setFilterPopoverEl ] = useState<HTMLButtonElement | null>(null);
  const onFilterButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => setFilterPopoverEl(e.currentTarget);
  const onFilterPopoverClose = () => setFilterPopoverEl(null);
  const { params: conditionsParams } = useFilterSearchParams<ConditionsFilter>(conditionsFilterSearchParams);
  const { params: medicationsParams } = useFilterSearchParams<MedicationsFilter>(medicationsFilterSearchParams);
  const { params: observationsParams } = useFilterSearchParams<ObservationsFilter>(observationsFilterSearchParams);
  const isConditionsTab = currentTab === 'conditions';
  const isMedicationsTab = currentTab === 'medications';
  const isObservationTab = currentTab === 'observations';
  const isConditionsFilterUsed = isFilterUsed(conditionsParams);
  const isMedicationsFilterUsed = isFilterUsed(medicationsParams);
  const isObservationsFilterUsed = isFilterUsed(observationsParams);

  const isAnyFilterUsed = isConditionsFilterUsed || isMedicationsFilterUsed || isObservationsFilterUsed;

  return (
    <Box mb={2} display="flex" justifyContent="space-between">
      <Button
        sx={{
          backgroundColor: 'white',
          width: '102px',
          textTransform: 'none',
        }}
        variant="text"
        onClick={onFilterButtonClick}
        endIcon={isAnyFilterUsed ? <FilterAlt /> : <FilterAltOffOutlined />}
      >
        Filters
      </Button>
      {isConditionsTab && <ConditionsFilterPopover data={filterPopoverEl} onClose={onFilterPopoverClose} />}
      {isMedicationsTab && <MedicationsFilterPopover data={filterPopoverEl} onClose={onFilterPopoverClose} />}
      {isObservationTab && <ObservationsFilterPopover data={filterPopoverEl} onClose={onFilterPopoverClose} />}
    </Box>
  );
};

export default EHRViewerControlPanel;
