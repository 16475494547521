import { UserAccessLevel } from 'api/User/types';
import {
  ADMIN_DASHBOARD_USERS_ROUTE,
  ADMIN_DASHBOARD_CLINICAL_PATIENTS_ROUTE,
  ADMIN_DASHBOARD_ADMIN_ROLES_ROUTE,
  ADMIN_DASHBOARD_NEWS_ROUTE,
  ADMIN_DASHBOARD_ORGANIZATIONS_ROUTE,
  ADMIN_DASHBOARD_REPORTS_ROUTE,
  ADMIN_DASHBOARD_SAMPLES_ROUTE,
  ADMIN_DASHBOARD_SHIPPING_ROUTE,
} from 'common/constants/urls';

// Pages that use the same user components
export const ADMIN_DASHBOARDS_USERS_PAGES = [ 'users', 'clinical-patients' ];

export const ADMIN_DASHBOARD_NAVIGATION_ITEMS: { to: string, label: string, roles: UserAccessLevel[] }[] = [
  { to: ADMIN_DASHBOARD_USERS_ROUTE,
    label: 'Users',
    roles: [ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.BIO_BANK_ADMIN, UserAccessLevel.DATA_SCIENCE_ADMIN ] },
  { to: ADMIN_DASHBOARD_CLINICAL_PATIENTS_ROUTE,
    label: 'Clinical patients',
    roles: [ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.BIO_BANK_ADMIN, UserAccessLevel.DATA_SCIENCE_ADMIN ] },
  { to: ADMIN_DASHBOARD_SHIPPING_ROUTE,
    label: 'Shipping',
    roles: [ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.BIO_BANK_ADMIN ] },
  { to: ADMIN_DASHBOARD_REPORTS_ROUTE,
    label: 'Reports',
    roles: [ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.BIO_BANK_ADMIN, UserAccessLevel.DATA_SCIENCE_ADMIN ] },
  { to: ADMIN_DASHBOARD_NEWS_ROUTE,
    label: 'News',
    roles: [ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN ] },
  { to: ADMIN_DASHBOARD_ADMIN_ROLES_ROUTE,
    label: 'Admin Roles',
    roles: [ UserAccessLevel.SUPER_ADMIN ] },
  { to: ADMIN_DASHBOARD_ORGANIZATIONS_ROUTE,
    label: 'Organizations',
    roles: [ UserAccessLevel.SUPER_ADMIN ] },
  { to: ADMIN_DASHBOARD_SAMPLES_ROUTE,
    label: 'Samples',
    roles: [ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.BIO_BANK_ADMIN, UserAccessLevel.DATA_SCIENCE_ADMIN ] },
];
