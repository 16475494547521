import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { ConditionsFilter, conditionsFilterSearchParams } from 'common/types/SearchParams';

import { useFormik } from 'formik';

const useConditionsFilter = (onSubmit: () => void) => {
  const { params, setFilter, clearFilter } = useFilterSearchParams<ConditionsFilter>(conditionsFilterSearchParams);

  const formikSubmit = (params: ConditionsFilter) => {
    setFilter(params);
    onSubmit();
  };

  const formik = useFormik<ConditionsFilter>({
    initialValues: params,
    onSubmit: formikSubmit,
    enableReinitialize: true,
  });

  const clearFormAndSearchParams = () => {
    formik.resetForm();
    clearFilter();
  };

  return { formik, clearFormAndSearchParams };
};

export default useConditionsFilter;
