import useConditionsFilter
  from 'components/AdminDashboard/components/EHRViewerPage/EHRViewerControlPanel/components/ConditionsFilterPopover/useConditionsFilter';
import useGetFilterData
  from 'components/AdminDashboard/components/EHRViewerPage/EHRViewerControlPanel/components/ConditionsFilterPopover/useGetFilterData';

import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  CircularProgress,
  Chip,
  Autocomplete,
} from '@mui/material';
import { FormikProvider } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

interface Props {
  onClose: () => void;
  data: HTMLButtonElement | null;
}

const ConditionsFilterPopover: React.FC<Props> = ({ data, onClose }) => {
  const { filterData, getFilterData, isLoading } = useGetFilterData();
  const { formik, clearFormAndSearchParams } = useConditionsFilter(onClose);

  const [ isStartDatePickerOpened, setIsStartDatePickerOpened ] = React.useState<boolean>(false);
  const openStartDatePicker = () => setIsStartDatePickerOpened(true);
  const closeStartDatePicker = () => setIsStartDatePickerOpened(false);

  const [ isEndDatePickerOpened, setIsEndDatePickerOpened ] = React.useState<boolean>(false);
  const openEndDatePicker = () => setIsEndDatePickerOpened(true);
  const closeEndDatePicker = () => setIsEndDatePickerOpened(false);

  const setDateField = (value: string | null, field: string) => {
    if (value) {
      const date = (value as unknown as DateTime).toISODate();
      formik.setFieldValue(field, date);
    } else {
      formik.setFieldValue(field, null);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getFilterData(abortController);

    return () => {
      abortController.abort();
    };
  }, [ getFilterData ]);

  return (
    <Popover
      open={Boolean(data)}
      anchorEl={data}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 1,
        sx: { padding: 4, width: 360 },
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display="flex" flexDirection="column" item container spacing={3}>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  value={formik.values.status}
                  name="status"
                  size="small"
                  variant="outlined"
                  IconComponent={isLoading ? () => null : undefined}
                  input={(
                    <OutlinedInput
                      size="small"
                      label="Status"
                      endAdornment={isLoading && (
                        <InputAdornment position="end" sx={{ marginBottom: 2 }}>
                          <CircularProgress size={16} />
                        </InputAdornment>
                      )}
                    />
                  )}
                  onChange={formik.handleChange}
                  disabled={isLoading}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {filterData.statuses.map(status => <MenuItem key={status} value={status}>{status}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="system-label">System</InputLabel>
                <Select
                  labelId="system-label"
                  value={formik.values.system}
                  name="system"
                  size="small"
                  variant="outlined"
                  IconComponent={isLoading ? () => null : undefined}
                  input={(
                    <OutlinedInput
                      size="small"
                      label="System"
                      endAdornment={isLoading && (
                        <InputAdornment position="end" sx={{ marginBottom: 2 }}>
                          <CircularProgress size={16} />
                        </InputAdornment>
                      )}
                    />
                  )}
                  onChange={formik.handleChange}
                  disabled={isLoading}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {filterData.systems.map(system => <MenuItem key={system} value={system}>{system}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="code"
                  options={[]}
                  freeSolo
                  onChange={(e, value) => {
                    formik.setFieldValue('code', value.join(','));
                  }}
                  renderTags={
                    (value: string[][], getTagProps) => value.map((option: string[], index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip variant="filled" label={option} key={key} {...tagProps} />
                      );
                    })
                  }
                  value={formik.values.code ? formik.values.code.split(',') : []}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      name="code"
                      label="Code"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  open={isStartDatePickerOpened}
                  label="Onset start date"
                  value={formik.values.startDate || null}
                  onChange={newValue => setDateField(newValue, 'startDate')}
                  onOpen={openStartDatePicker}
                  onClose={closeStartDatePicker}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                      size="small"
                      fullWidth
                      onClick={openStartDatePicker}
                    />
                  )}
                  disabled={false}
                  disableMaskedInput
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  open={isEndDatePickerOpened}
                  label="Onset end date"
                  value={formik.values.endDate || null}
                  onChange={newValue => setDateField(newValue, 'endDate')}
                  onOpen={openEndDatePicker}
                  onClose={closeEndDatePicker}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                      size="small"
                      fullWidth
                      onClick={openEndDatePicker}
                    />
                  )}
                  disabled={false}
                  disableMaskedInput
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} marginTop={1} container flexDirection="row-reverse">
              <Button type="submit" variant="contained" size="large" sx={{ marginLeft: 2 }}>
                Apply
              </Button>
              <Button variant="outlined" size="large" onClick={clearFormAndSearchParams}>
                Clear
              </Button>
            </Grid>

          </Grid>
        </form>
      </FormikProvider>
    </Popover>
  );
};

export default ConditionsFilterPopover;
