import { RootState } from 'store';
import { ADMIN_DASHBOARD_USERS_ADD_ROUTE, ADMIN_DASHBOARD_CLINICAL_PATIENTS_ADD_ROUTE } from 'common/constants/urls';
import { isDataScienceAdmin } from 'common/helpers/user';
import { isFilterUsed } from 'common/helpers/filters';
import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { UsersFilter, usersFilterSearchParams } from 'common/types/SearchParams';
import AddUserPopover from 'components/AdminDashboard/components/UsersPage/components/UsersControlPanel/components/AddUserPopover/AddUserPopover';
import BatchUploadSummary from 'components/AdminDashboard/components/UsersPage/components/UsersControlPanel/components/BatchUploadSummary/BatchUploadSummary';
import ExportPopover from 'components/AdminDashboard/components/UsersPage/components/UsersControlPanel/components/ExportPopover/ExportPopover';
import UsersFilterPopover from 'components/AdminDashboard/components/UsersPage/components/UsersControlPanel/components/UsersFilterPopover/UsersFilterPopover';
import { UserPageProps } from 'components/AdminDashboard/components/UsersPage/types';

import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import FilterAltOffOutlined from '@mui/icons-material/FilterAltOffOutlined';
import FilterAlt from '@mui/icons-material/FilterAlt';
import Download from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UsersControlPanel: React.FC<UserPageProps> = ({ isClinicalPatientPage }) => {
  const navigate = useNavigate();
  const { myUser } = useSelector((store: RootState) => store.user);
  const canAddUsers = !isDataScienceAdmin(myUser);
  const { params } = useFilterSearchParams<UsersFilter>(usersFilterSearchParams);
  const [ isExporting, setIsExporting ] = useState<boolean>(false);

  const [ addUserPopoverEl, setAddUserPopoverEl ] = useState<HTMLButtonElement | null>(null);
  const onAddUserButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => setAddUserPopoverEl(event.currentTarget);
  const onAddUserPopoverClose = () => setAddUserPopoverEl(null);

  const [ exportPopoverEl, setExportPopoverEl ] = useState<HTMLButtonElement | null>(null);
  const onExportButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => setExportPopoverEl(event.currentTarget);
  const onExportPopoverClose = () => setExportPopoverEl(null);

  const [ usersFilterPopoverEl, setUsersFilterPopoverEl ] = useState<HTMLButtonElement | null>(null);
  const onUsersFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => setUsersFilterPopoverEl(event.currentTarget);
  const onUsersFilterPopoverClose = () => setUsersFilterPopoverEl(null);

  const [ isBatchUploadSummaryOpen, setIsBatchUploadSummaryOpen ] = useState<boolean>(false);
  const openBatchUploadSummary = () => setIsBatchUploadSummaryOpen(true);
  const closeBatchUploadSummary = () => {
    setIsBatchUploadSummaryOpen(false);
    onAddUserPopoverClose();
  };

  const onAddUserClick = () => {
    const route = isClinicalPatientPage ? ADMIN_DASHBOARD_CLINICAL_PATIENTS_ADD_ROUTE : ADMIN_DASHBOARD_USERS_ADD_ROUTE;
    navigate(route);
  };

  return (
    <Box mb={2} display="flex" justifyContent="space-between">
      <Button
        sx={{
          backgroundColor: 'white',
          width: '102px',
          textTransform: 'none',
        }}
        variant="text"
        onClick={onUsersFilterButtonClick}
        endIcon={isFilterUsed(params) ? <FilterAlt /> : <FilterAltOffOutlined />}
      >
        Filters
      </Button>
      <UsersFilterPopover data={usersFilterPopoverEl} onClose={onUsersFilterPopoverClose} />
      <Box>
        <Button
          sx={{
            backgroundColor: 'white',
            width: '102px',
            textTransform: 'none',
          }}
          variant="text"
          onClick={onExportButtonClick}
          endIcon={<Download />}
          disabled={isExporting}
        >
          Export
        </Button>
        <ExportPopover data={exportPopoverEl} onClose={onExportPopoverClose} setIsExporting={setIsExporting} isClinicalPatientPage={isClinicalPatientPage} />
        {canAddUsers && (
          <>
            <Button
              sx={{
                backgroundColor: 'white',
                minWidth: '102px',
                textTransform: 'none',
                marginLeft: 2,
              }}
              variant="text"
              onClick={onAddUserButtonClick}
            >
              Add {isClinicalPatientPage ? 'Clinical Patient' : 'User'}
            </Button>
            <AddUserPopover
              data={addUserPopoverEl}
              onAddUserClick={onAddUserClick}
              onClose={onAddUserPopoverClose}
              onBatchUpload={openBatchUploadSummary}
              isClinicalPatientPage={isClinicalPatientPage}
            />
            <BatchUploadSummary open={isBatchUploadSummaryOpen} onClose={closeBatchUploadSummary} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default UsersControlPanel;
