import { exportUsersRequest } from 'api/User/api';
import { FileFormat } from 'api/User/types';
import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { UsersFilter, usersFilterSearchParams } from 'common/types/SearchParams';
import { downloadCSVFile } from 'common/helpers/downloadCSVFile';

import { useCallback } from 'react';

export const useExportUsers = (setIsExporting: (exporting: boolean) => void, onFinish: () => void) => {
  const { params } = useFilterSearchParams<UsersFilter>(usersFilterSearchParams);

  const exportUsers = useCallback((clinicalPatients: boolean, fileFormat: FileFormat) => {
    setIsExporting(true);

    exportUsersRequest(params, clinicalPatients, fileFormat)
      .then((response) => {
        downloadCSVFile('exported_users', response.data, fileFormat);
      })
      .finally(() => {
        setIsExporting(false);
        onFinish();
      });
  }, [ params, setIsExporting, onFinish ]);

  return { exportUsers };
};
