import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { MedicationsFilter, medicationsFilterSearchParams } from 'common/types/SearchParams';
import { HeadCell } from 'common/types/MuiComponents';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import DataTable from 'common/components/DataTable/DataTable';
import { formatDate } from 'common/helpers/dateTime';
import useGetMedications from 'components/AdminDashboard/components/EHRViewerPage/Medications/hooks';

import React, { useCallback, useMemo } from 'react';
import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const Medications: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { medications, getMedications, isLoading, error, pagination } = useGetMedications(userId!);
  const { params } = useFilterSearchParams<MedicationsFilter>(medicationsFilterSearchParams);

  const fetchMedications = useCallback((abortController: AbortController) => {
    getMedications(params, abortController);
  }, [ getMedications, params ]);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'status',
      numeric: false,
      label: 'Status',
    },
    {
      id: 'startDate',
      numeric: false,
      label: 'Effective start',
    },
    {
      id: 'endDate',
      numeric: false,
      label: 'Effective end',
    },
    {
      id: 'dosageValue',
      numeric: false,
      label: 'Dosage value',
    },
    {
      id: 'dosageUnit',
      numeric: false,
      label: 'Dosage unit',
    },
    {
      id: 'codeCode',
      numeric: false,
      label: 'Code',
    },
    {
      id: 'codeSystem',
      numeric: false,
      label: 'Code system',
    },
    {
      id: 'codeDisplay',
      numeric: false,
      label: 'Code display',
    },
  ], []);

  const renderRows = useCallback(() => medications.map(row => (
    <TableRow hover tabIndex={-1} key={row.medicationStatementId}>

      <TableCell>{row.medicationStatementStatus}</TableCell>

      <TableCell>{formatDate(row.medicationStatementEffectiveStartDate)}</TableCell>

      <TableCell>{formatDate(row.medicationStatementEffectiveEndDate)}</TableCell>

      <TableCell>{row.medicationStatementDosageValue}</TableCell>

      <TableCell>{row.medicationStatementDosageUnit}</TableCell>

      <TableCell>{row.codeCode}</TableCell>

      <TableCell>{row.codeSystem}</TableCell>

      <TableCell>{row.codeDisplay}</TableCell>

    </TableRow>
  )), [ medications ]);

  return (
    <LoadingContainer isLoading={isLoading}>

      {error && (
        <Grid item xs={12} padding={2}>
          <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
            {error}
          </Typography>
        </Grid>
      )}

      <DataTable
        pagination={pagination}
        headCells={headCells}
        renderRows={renderRows}
        updateDataCallback={fetchMedications}
      />

    </LoadingContainer>
  );
};

export default Medications;
