import { useCurrentTab } from 'containers/hooks/useCurrentTab';
import { EHRViewerTab } from 'containers/types/tabs';
import Conditions from 'components/AdminDashboard/components/EHRViewerPage/Conditions/Conditions';
import EHRViewerControlPanel from 'components/AdminDashboard/components/EHRViewerPage/EHRViewerControlPanel/EHRViewerControlPanel';
import Medications from 'components/AdminDashboard/components/EHRViewerPage/Medications/Medications';
import Observations from 'components/AdminDashboard/components/EHRViewerPage/Observations/Observations';

import React from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { Box, Paper, Tab, Tabs } from '@mui/material';

const EHRViewerContainer: React.FC = () => {
  const tabs = Object.values(EHRViewerTab);
  const currentTab = useCurrentTab(tabs) as EHRViewerTab;

  return (
    <Box data-testid="ehr-viewer-container">
      <EHRViewerControlPanel currentTab={currentTab} />

      <Paper sx={{ maxWidth: '100%' }}>
        <Tabs value={currentTab} indicatorColor="secondary" textColor="secondary" sx={{ borderTopLeftRadius: '4px' }}>
          <Tab label="Conditions" value={tabs[0]} to={tabs[0]} component={Link} />
          <Tab label="Medications" value={tabs[1]} to={tabs[1]} component={Link} />
          <Tab label="Observations" value={tabs[2]} to={tabs[2]} component={Link} />
        </Tabs>
        <Routes>
          <Route path={tabs[0]} element={<Conditions />} />
          <Route path={tabs[1]} element={<Medications />} />
          <Route path={tabs[2]} element={<Observations />} />
          <Route path="/*" element={<Navigate to={tabs[0]} />} />
        </Routes>
      </Paper>
    </Box>
  );
};

export default EHRViewerContainer;
