import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { ObservationsFilter, observationsFilterSearchParams } from 'common/types/SearchParams';
import { HeadCell } from 'common/types/MuiComponents';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import DataTable from 'common/components/DataTable/DataTable';
import ObservationRow from 'components/AdminDashboard/components/EHRViewerPage/Observations/components/ObservationRow/ObservationRow';
import useGetObservations from 'components/AdminDashboard/components/EHRViewerPage/Observations/hooks';

import React, { useCallback, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const Observations: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { observations, getObservations, isLoading, error, pagination } = useGetObservations(userId!);
  const { params } = useFilterSearchParams<ObservationsFilter>(observationsFilterSearchParams);

  const fetchObservations = useCallback((abortController: AbortController) => {
    getObservations(params, abortController);
  }, [ getObservations, params ]);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'action',
      numeric: false,
      label: '',
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
    },
    {
      id: 'issued',
      numeric: false,
      label: 'Issued',
    },
    {
      id: 'organization',
      numeric: false,
      label: 'Organization',
    },
    {
      id: 'codeCode',
      numeric: false,
      label: 'Code',
    },
    {
      id: 'codeSystem',
      numeric: false,
      label: 'Code system',
    },
    {
      id: 'codeDisplay',
      numeric: false,
      label: 'Code display',
    },
    {
      id: 'categoryCode',
      numeric: false,
      label: 'Category code',
    },
    {
      id: 'categorySystem',
      numeric: false,
      label: 'Category system',
    },
    {
      id: 'categoryDisplay',
      numeric: false,
      label: 'Category display',
    },
  ], []);

  const renderRows = useCallback(() => observations.map(row => <ObservationRow row={row} key={row.observationId} />), [ observations ]);

  return (
    <LoadingContainer isLoading={isLoading}>

      {error && (
        <Grid item xs={12} padding={2}>
          <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
            {error}
          </Typography>
        </Grid>
      )}

      <DataTable
        pagination={pagination}
        headCells={headCells}
        renderRows={renderRows}
        updateDataCallback={fetchObservations}
      />

    </LoadingContainer>
  );
};

export default Observations;
