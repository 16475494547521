import { ReduxDispatch } from 'store';
import { getOrganizationsRequest } from 'api/Organizations/api';
import { SET_ORGANIZATIONS, SET_ORGANIZATIONS_LOADING, SET_ORGANIZATIONS_ERROR } from 'store/organizations/types';

import i18next from 'i18next';

export const getOrganizations = (page: string, pageSize: string) => (dispatch: ReduxDispatch) => {
  dispatch({ type: SET_ORGANIZATIONS_LOADING, value: true });

  getOrganizationsRequest(page, pageSize)
    .then(({ data }) => {
      dispatch({ type: SET_ORGANIZATIONS, data: data || {} });
    })
    .catch((error) => {
      const value = error?.response?.data?.message || i18next.t(('errors.server_error_try_again'));

      dispatch({ type: SET_ORGANIZATIONS_ERROR, value });
    })
    .finally(() => {
      dispatch({ type: SET_ORGANIZATIONS_LOADING, value: false });
    });
};
