import useFilterSearchParams from 'common/hooks/useFilterSearchParams';
import { observationsFilterSearchParams, ObservationsFilter } from 'common/types/SearchParams';

import { useFormik } from 'formik';

const useObservationsFilter = (onSubmit: () => void) => {
  const { params, setFilter, clearFilter } = useFilterSearchParams<ObservationsFilter>(observationsFilterSearchParams);

  const formikSubmit = (params: ObservationsFilter) => {
    setFilter(params);
    onSubmit();
  };

  const formik = useFormik<ObservationsFilter>({
    initialValues: params,
    onSubmit: formikSubmit,
    enableReinitialize: true,
  });

  const clearFormAndSearchParams = () => {
    formik.resetForm();
    clearFilter();
  };

  return { formik, clearFormAndSearchParams };
};

export default useObservationsFilter;
