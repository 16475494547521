import { resendActivateLinkRequest } from 'api/User/api';

import { Link } from 'react-router-dom';
import React, { ReactElement, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const useResendActivationLink = () => {
  const { t } = useTranslation();
  const [ email, setEmail ] = useState<string>('');
  const [ submitError, setSubmitError ] = useState<string | ReactElement>('');
  const [ success, setSuccess ] = useState<boolean>(false);
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);

  const resendActivationLink = useCallback(() => {
    setSubmitError('');
    setSuccess(false);
    setIsSubmitting(true);

    resendActivateLinkRequest(email)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        if (error?.response.data.message === 'User already activated') {
          const errorMsg = (
            <span>
              <Trans t={t} i18nKey="verify_page.already_activated">
                This account is already activated. Go to <Link to="/dashboard">Sign in</Link>
              </Trans>
            </span>
          );
          setSubmitError(errorMsg);
        } else {
          setSubmitError(error?.response.data.message || t('errors.server_error_try_again'));
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [ email, t ]);

  return { submitError, success, isSubmitting, resendActivationLink, email, setEmail };
};

export default useResendActivationLink;
