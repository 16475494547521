import { Pagination } from 'common/types/Pagination';
import { getUserReports } from 'api/Reports/api';
import { UserReport } from 'api/Reports/types';
import { ReportsFilter } from 'common/types/SearchParams';

import { useCallback, useState } from 'react';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';

export const useUserReports = () => {
  const { t } = useTranslation();

  const [ userReports, setUserReports ] = useState<UserReport[]>([]);
  const [ pagination, setPagination ] = useState<Pagination | undefined>();
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  const requestUserReports = useCallback((filter: ReportsFilter, abortController: AbortController) => {
    setLoading(true);
    setError('');
    getUserReports(filter, abortController)
      .then(({ data }) => {
        if (data) {
          setUserReports(data.results);
          setPagination(omit(data, 'results'));
        }
      })
      .catch((error) => {
        if (!abortController?.signal.aborted) {
          setError(error?.response?.data?.message || t('errors.server_error_try_again'));
        }
      })
      .finally(() => {
        if (!abortController?.signal.aborted) {
          setLoading(false);
        }
      });
  }, [ t ]);

  return {
    requestUserReports,
    userReports,
    pagination,
    setUserReports,
    isLoading,
    error,
  };
};
